@import url("https://fonts.googleapis.com/css2?family=Dosis:wght@400:600&display=swap");

body {
  padding: 0;
  margin: 0;
}

#navbar {
  background-color: #f8f9fa;
  border-bottom: 1px solid #e7e7e7;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#student-site {
  width: 100vw;
  height: 100vh;
  border: none;
  padding: 0;
  margin: 0;
}

#home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: #f8f9fa;
}

.ticker-container {
  font-family: "Dosis", sans-serif;
  font-size: 20px;
  overflow: hidden;
  height: 250px;
  width: fit-content;
}

#ticker {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  animation: ticker 20s linear infinite;
}

#ticker li {
  height: 100px;
  line-height: 50px;
}

@keyframes ticker {
  0% {
    transform: translateY(200%);
  }
  100% {
    transform: translateY(-100%);
  }
}
